.lecturerQualificationDownload .operationControl {
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start;
  align-items: flex-start;
}
.lecturerQualificationDownload .mabtm {
  padding-bottom: 0.675rem;
  border-bottom: 1px solid #f5f5f6;
}
.lecturerQualificationDownload .searchboxItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}
.lecturerQualificationDownload .searchboxItem .bgc-bv {
  width: 72px;
}
.lecturerQualificationDownload .searchboxItem .el-cascader {
  height: 32px !important;
}
.lecturerQualificationDownload .searchboxItem .itemLabel {
  width: 155px;
  text-align: right;
}
