

































































































































































































































































































.lecturerQualificationDownload {
  .operationControl {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .mabtm {
    padding-bottom: 0.675rem;
    border-bottom: 1px solid #f5f5f6;
  }
  .searchboxItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    .bgc-bv {
      width: 72px;
    }
    .el-cascader {
      height: 32px !important;
    }
    .itemLabel {
      width: 155px;
      text-align: right;
    }
  }
}
